import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VuePaginateScroll from "@core/components/paginate-scroll";
Vue.component("VuePaginateScroll", VuePaginateScroll);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// LOAD FILTERS //
import "@/@core/utils/filters.js";

import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/pt-br");
Vue.use(VueMoment, { moment });

import jwtConfig from "@core/auth/jwt/jwtDefaultConfig";
import VueJWT from "hpsweb-vuejs-jwt";
Vue.use(VueJWT, {
  keyName: jwtConfig.storageTokenKeyName,
});

import AuthPlugin from "@core/auth/authPlugin";
Vue.use(AuthPlugin);

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Axios Mock Adapter
import "@/@fake-db/db";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
