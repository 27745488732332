import Vue from "vue";

// axios
import axios from "axios";

let baseURL = process.env.VUE_APP_BASEURL;
if (process.env.NODE_ENV === "development")
  baseURL = process.env.VUE_APP_DEV_BASEURL;

const axiosIns = axios.create({
  baseURL: baseURL,

  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
