import Vue from "vue";
import store from "@/store";
import router from "@/router";
import ability from "@/libs/acl/ability";
import EventEmitter from "events";

import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";

const loginEvent = "loginEvent";
class AuthService extends EventEmitter {
  logOut(args = { redirectTo: false, redirect: true }) {
    const options = {
      redirect: args.redirect || true,
      redirectTo: args.redirectTo || false,
    };

    if (Vue.$jwt.getToken()) localStorage.removeItem("accessToken");

    // Change role on logout. Same value as initialRole of acj.js
    // this.$acl.change("admin");
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    // Remove userData from localStorage
    localStorage.removeItem("userData");
    store.commit("account/SET_ACCOUNT", {});
    // Reset ability
    ability.update(initialAbility);

    this.emit(loginEvent, { loggedIn: false });

    // console.log(router.currentRoute.path);
    // Redirect to login page
    if (options.redirect && !options.to)
      router.push({ name: "auth-login" }).catch(() => {});
    else if (options.redirect && options.to)
      router
        .push({
          name: "auth-login",
          query: { to: router.currentRoute.path },
        })
        .catch(() => {});
  }

  isAuthenticated() {
    let isExpired = Vue.$jwt.isExpired();
    return !isExpired;
  }
}

export default new AuthService();
