import Vue from "vue";
import moment from "moment";

Vue.filter("ucFirst", function(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  let arr = value.split(" ");
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("title", function(value, replacer = "_") {
  if (!value) return "";
  value = value.toString();

  let arr = value.split(replacer);
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("truncate", function(value, limit) {
  return value.substring(0, limit);
});

Vue.filter("tailing", function(value, tail) {
  return value + tail;
});

Vue.filter("dateFormat", function(value, format = "DD/MM/YYYY HH:mm") {
  if (value) {
    return moment(String(value)).format(format);
  }
});

Vue.filter("time", function(value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      return hours + ":" + min + " " + time;
    }
    return hours + ":" + min;
  }
});

Vue.filter("date", function(value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return date + " " + month;
  else return date + " " + month + " " + year;
});

Vue.filter("month", function(val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return regx.exec(val)[1] + " " + regx.exec(val)[2];
  }
});

Vue.filter("csv", function(value) {
  return value.join(", ");
});

Vue.filter("filter_tags", function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("k_formatter", function(num) {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
});

Vue.filter("typeOf", function(obj) {
  return (
    `${obj} - ` +
    {}.toString
      .call(obj)
      .split(" ")[1]
      .slice(0, -1)
      .toLowerCase()
  );
});

Vue.filter("isEmpty", function(obj) {
  return Object.keys(obj).length === 0;
});

Vue.filter("truncate", function(text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter("phoneNumber", function(phone) {
  phone = phone.replace(/[^\d]/g, "");

  if (phone.length == 11)
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  else if (phone.length == 10)
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");

  return null;
});

Vue.filter("formatMoney", function(number, decPlaces, decSep, thouSep) {
  (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = typeof decSep === "undefined" ? "," : decSep);
  thouSep = typeof thouSep === "undefined" ? "." : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
});
