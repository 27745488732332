export default [
  {
    path: "/calendar/:aluno?",
    name: "forca-calendar",
    component: () => import("@/views/apps/calendar/Calendar.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/financeiro",
    name: "forca-financeiro",
    component: () => import("@/views/apps/financeiro/Financeiro.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/ajustes",
    name: "forca-ajustes",
    component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    meta: {
      authRequired: true,
    },
  },

  // *===============================================---*
  // *--------- NOTIFICATIONS & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: "/notificacoes",
    name: "forca-notifications",
    component: () => import("@/views/pages/notification/Notification.vue"),
    meta: {
      authRequired: true,
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/notificacoes/:folder",
    name: "forca-notifications-folder",
    component: () => import("@/views/pages/notification/Notification.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "forca-notifications",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/notificacoes/label/:label",
    name: "forca-notifications-label",
    component: () => import("@/views/pages/notification/Notification.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "forca-notifications",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },

  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/register-v1",
    name: "auth-register-v1",
    component: () => import("@/views/pages/authentication/Register-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/register-v2",
    name: "auth-register-v2",
    component: () => import("@/views/pages/authentication/Register-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/reset-password/:token",
    name: "auth-reset-password",
    component: () =>
      import("@/views/pages/authentication/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/reset-password-v2",
    name: "auth-reset-password-v2",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
];
