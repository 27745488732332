import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import dashboard from "./routes/dashboard";
import forca from "./routes/forca";
// import apps from "./routes/apps";
// import uiElements from "./routes/ui-elements/index";
// import chartsMaps from "./routes/charts-maps";
// import formsTable from "./routes/forms-tables";
// import others from "./routes/others";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "forca-calendar" } },
    ...dashboard,
    ...forca,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!isLoggedIn && to.meta.authRequired) {
    Vue.prototype.$auth.logOut({ redirect: false });
    return next({
      name: "auth-login",
      query: { to: to.path },
    });
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn)
      return next({
        name: "auth-login",
        query: { to: to.path },
      });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
